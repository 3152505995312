import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Container, Box, Button, Grid } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Fix for default icon not appearing in leaflet
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const logos = {
  metro: '/images/logo/logoMetro.jpg',
  autobus: '/images/logo/logoBus.jpg'
};

const mapContainerStyle = {
  width: '100%', // Ancho completo del contenedor
  height: '300px', // Ajuste de la altura del mapa
  marginBottom: '20px' // Espacio debajo del mapa
};

// Coordenadas del local
const center = {
  lat: 40.343888429896786, // Latitud correcta del local
  lng: -3.8337148713741978  // Longitud correcta del local
};

const WhereAmIContent = () => {
  const [entry, setEntry] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isShortScreen = useMediaQuery('(max-height:800px)');

  useEffect(() => {
    // Cargar datos desde el archivo JSON
    axios.get('/data/whereami.json')
      .then(response => {
        if (response.data.entries.length > 0) {
          setEntry(response.data.entries[0]);
        }
      })
      .catch(error => console.error('Error loading data:', error));
  }, []);

  if (!entry) return <div>Loading...</div>;

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        flexDirection="column"
        position="relative"
      >
        <Card variant="outlined" sx={{ width: '100%', backgroundColor: '#f5f5f5', boxShadow: 3, overflow: 'hidden', position: 'relative' }}>
          <Box height={isMobile? {} : "70vh"} sx={{ height: '70vh', overflowY: 'scroll', scrollbarWidth: 'none', WebkitOverflowScrolling: 'touch', padding: '20px' }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {entry.title}
              </Typography>
              <Typography variant="h6" color="textSecondary" gutterBottom>
                {entry.subtitle}
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={9}>
                  <MapContainer center={center} zoom={18} style={mapContainerStyle}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={center}>
                      <Popup>
                        {entry.title}
                      </Popup>
                    </Marker>
                  </MapContainer>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box mb={2}>
                    <Typography variant="h6" component="div" gutterBottom>
                      Metro
                    </Typography>
                    <Typography variant="body1" paragraph>
                      Estación ALCORCÓN CENTRAL. Línea 12.
                    </Typography>
                    <Button 
                      variant="contained" 
                      href="https://www.metromadrid.es/es/linea/12" 
                      target="_blank"
                      sx={{ padding: 0, backgroundColor: '#f5f5f5', '&:hover': { backgroundColor: '#f0f0f0' } }}
                    >
                      <img
                        src={logos.metro}
                        alt="Metro logo"
                        style={{ width: 50, height: 50, objectFit: 'contain', padding: 1 }}
                      />
                    </Button>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6" component="div" gutterBottom>
                      Línea de autobuses
                    </Typography>
                    <Typography variant="body1" paragraph>
                      De Blás y cia. Nº 512.
                    </Typography>
                    <Button 
                      variant="contained" 
                      href="https://www.blasycia.com/lineas/512" 
                      target="_blank"
                      sx={{ padding: 0, backgroundColor: '#f5f5f5', '&:hover': { backgroundColor: '#f0f0f0' } }}
                    >
                      <img
                        src={logos.autobus}
                        alt="Autobús logo"
                        style={{ width: 50, height: 50, objectFit: 'contain', padding: 1 }}
                      />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {entry.sections.slice(2).map((section, index) => (
                <Grid container spacing={4} alignItems="center" key={index}>
                  {index % 2 === 0 ? (
                    <>
                      <Grid item xs={12} md={6}>
                        <img
                          src={section.image}
                          alt={section.title}
                          style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" component="div" gutterBottom>
                          {section.title}
                        </Typography>
                        <Typography variant="body1" paragraph>
                          {section.description}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                        <Typography variant="h6" component="div" gutterBottom>
                          {section.title}
                        </Typography>
                        <Typography variant="body1" paragraph>
                          {section.description}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                        <img
                          src={section.image}
                          alt={section.title}
                          style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              ))}
            </CardContent>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default WhereAmIContent;
