import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Inicio', to: '/' },
    { text: '¿Quién soy?', to: '/whoami' },
    { text: '¿Dónde estoy?', to: '/whereami' },
    { text: 'Exposiciones', to: '/shoponline' },
    { text: 'Prensa', to: '/press' },
    { text: 'Contacto', to: '/contact' },
    { text: 'Media', to: '/media' },
  ];

  const renderButtons = () => (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {menuItems.map((item, index) => (
        <Button
          key={index}
          sx={{ color: 'inherit', '&:hover': { color: '#fff' } }}
          component={Link}
          to={item.to}
        >
          {item.text}
        </Button>
      ))}
    </Box>
  );

  const renderDrawer = () => (
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {menuItems.map((item, index) => (
            <ListItem button key={index} component={Link} to={item.to}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: '#D29B07', color: "#000"}}>
      <Toolbar sx={{ justifyContent: isMobile || isTablet ? 'space-between' : 'center' }}>
        {(isMobile || isTablet) && (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Crispin Mateos Mateos
            </Typography>
          </>
        )}
        {!(isMobile || isTablet) && renderButtons()}
      </Toolbar>
      {(isMobile || isTablet) && renderDrawer()}
    </AppBar>
  );
};

export default Header;
