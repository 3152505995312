import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import WhoAmI from "./pages/WhoAmI";
import WhereAmI from "./pages/WhereAmI";
import ShopOnline from "./pages/ShopOnline";
import Press from "./pages/Press";
import Contact from "./pages/Contact";
import Media from "./pages/Media";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "xl"));

  return (
    <Router>
      <Box>
        <Header />
      </Box>
      <Box
        component="main"
        display="flex"
        justifyContent="center"
        style={
          isMobile
            ? {
                alignItems: "inherit",
                overflowY: "scroll",
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
                width: "100%",
                height: "100%",
                backgroundColor: "#f5f5f5",
              }
            : isTablet
            ? {
                alignItems: "center",
                overflowY: "scroll",
                scrollbarWidth: "none",
                WebkitOverflowScrolling: "touch",
                width: "100%",
                height: "60vh",
              }
            : {
                /*backgroundImage: `url(/images/main/brooklynBridge.jpg)`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",*/
                alignItems: "center",
              }
        }
        sx={{}}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/whoami" element={<WhoAmI />} />
          <Route path="/whereami" element={<WhereAmI />} />
          <Route path="/shoponline" element={<ShopOnline />} />
          <Route path="/press" element={<Press />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/media" element={<Media />} />
        </Routes>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
