import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
  CardMedia,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ShopOnlineContent = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sections, setSections] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isShortScreen = useMediaQuery("(max-height:800px)");

  useEffect(() => {
    // Cargar datos desde el archivo JSON
    axios
      .get("/data/shoponline.json")
      .then((response) => {
        const loadedSections = response.data.sections.flatMap((section) =>
          section.subsections
            ? section.subsections.map((subsection) => ({
                ...subsection,
                mainTitle: section.title,
              }))
            : [section]
        );
        setData(response.data.sections);
        setSections(loadedSections);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  useEffect(() => {
    if (sections.length === 0) return;

    const interval = setInterval(() => {
      handleNext();
    }, 120000); // 2 minutos

    return () => clearInterval(interval);
  }, [sections]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sections.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + sections.length) % sections.length
    );
  };

  if (sections.length === 0) return <div>Loading...</div>;

  const currentSection = sections[currentIndex];

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
        sx={{
          height: "80vh",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            width: isMobile ? "100%" : isTablet ? "70%" : "900px",
            maxWidth: "900px",
            backgroundColor: "#f5f5f5",
            boxShadow: 3,
            position: "relative",
            overflowY: "scroll",
            scrollbarWidth: "none",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <CardContent>
              {currentSection.mainTitle && (
                <Typography variant="h5" component="div" gutterBottom>
                  {currentSection.mainTitle}
                </Typography>
              )}
              <Typography variant="h6" component="div" gutterBottom>
                {currentSection.title}
              </Typography>
              {
                currentSection.image && (
                  <CardMedia component={"img"} src={currentSection.image} style={{width: "50px"}}></CardMedia>
                )
              }
              {currentSection.items ? (
                <List>
                  {currentSection.items.map((item, itemIndex) => (
                    <ListItem key={itemIndex}>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box mt={2}>
                  <Typography variant="body1" paragraph>
                    {currentSection.description}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Contacto:{" "}
                    <a href={`mailto:${currentSection.contact}`}>
                      {currentSection.contact}
                    </a>
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {currentSection.footer}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Box>
          <IconButton
            onClick={handlePrevious}
            disabled={sections.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={sections.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowForward />
          </IconButton>
        </Card>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: "center", padding: "10px" }}
        >
          {sections.map((_, index) => (
            <CircleIcon
              key={index}
              fontSize="small"
              sx={{
                color: index === currentIndex ? "primary.main" : "grey.500",
              }}
            />
          ))}
        </Stack>
      </Box>
    </Container>
  );
};

export default ShopOnlineContent;
