import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  IconButton,
  Stack,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import TvIcon from "@mui/icons-material/Tv";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RadioIcon from "@mui/icons-material/Radio";
import CircleIcon from "@mui/icons-material/Circle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MediaContent = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isShortScreen = useMediaQuery("(max-height:800px)");

  useEffect(() => {
    axios
      .get("/data/media.json")
      .then((response) => {
        setData(response.data.sections[0].subsections);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    const interval = setInterval(() => {
      handleNextSection();
    }, 120000); // 2 minutos

    return () => clearInterval(interval);
  }, [data]);

  const handleNextSection = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePreviousSection = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  const handleSectionClick = (index) => {
    setCurrentIndex(index);
  };

  if (data.length === 0) return <div>Loading...</div>;

  const currentSection = data[currentIndex];

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        overflowY="scroll"
        position="relative"
        sx={{ height: "80vh" }}
      >
        <Card
          variant="outlined"
          sx={{
            width: "100%",
            maxWidth: "900px",
            backgroundColor: "#f5f5f5",
            boxShadow: 3,
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            overflowY: "scroll",
          }}
        >
          <Box sx={{ padding: "20px" }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {currentSection.title}
              </Typography>
              <List>
                {currentSection.items.map((item, itemIndex) => (
                  <ListItem key={itemIndex} alignItems="flex-start">
                    <ListItemIcon>
                      {item.type === "tv" && <TvIcon />}
                      {item.type === "youtube" && <YouTubeIcon />}
                      {item.type === "radio" && <RadioIcon />}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="body1" component="span">
                          {item.content}
                        </Typography>
                      }
                      secondary={
                        item.link && (
                          <Link href={item.link} target="_blank" rel="noopener">
                            Link al contenido
                          </Link>
                        )
                      }
                    />
                    {item.links &&
                      item.links.map((link, linkIndex) => (
                        <Box key={linkIndex} ml={4}>
                          <Typography variant="body2" component="span">
                            {link.part}:{" "}
                            <Link
                              href={link.link}
                              target="_blank"
                              rel="noopener"
                            >
                              Link al contenido
                            </Link>
                          </Typography>
                        </Box>
                      ))}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Box>
          <IconButton
            onClick={handlePreviousSection}
            disabled={data.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNextSection}
            disabled={data.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowForward />
          </IconButton>
        </Card>
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: "center", padding: "10px" }}
        >
          {data.map((_, index) => (
            <CircleIcon
              key={index}
              fontSize="small"
              sx={{
                color: index === currentIndex ? "primary.main" : "grey.500",
                cursor: "pointer",
              }}
              onClick={() => handleSectionClick(index)}
            />
          ))}
        </Stack>
      </Box>
    </Container>
  );
};

export default MediaContent;
