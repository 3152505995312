import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  CardMedia,
  Grid,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const WhoAmIContent = () => {
  const [data, setData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSection, setCurrentSection] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isShortScreen = useMediaQuery("(max-height:1000px)");

  const sections = [
    "biography",
    "courses",
    "awards",
    "works",
    "collectionsAndMuseums",
  ];

  useEffect(() => {
    axios
      .get("/data/whoami.json")
      .then((response) => {
        setData(response.data);
        setCurrentSection(sections[0]);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  useEffect(() => {
    if (!data) return;

    const interval = setInterval(() => {
      handleNext();
    }, 120000); // 2 minutos

    return () => clearInterval(interval);
  }, [data]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % sections.length;
      setCurrentSection(sections[nextIndex]);
      return nextIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const prevIndexAdjusted =
        (prevIndex - 1 + sections.length) % sections.length;
      setCurrentSection(sections[prevIndexAdjusted]);
      return prevIndexAdjusted;
    });
  };

  if (!data) return <div>Loading...</div>;

  const renderContent = () => {
    switch (currentSection) {
      case "biography":
        return (
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              {data.biography.name} ({data.biography.nickname})
            </Typography>
            <Typography variant="body1" paragraph>
              Nací en {data.biography.birth.city} en {data.biography.birth.year}
              . Soy titulado técnico en estructuras metálicas por la Escuela de
              Artes y Oficios de Madrid. He realizado varios cursos de grabado,
              uno de 148 horas y otro de perfeccionamiento del grabado
              calcográfico, gracias al cual fui seleccionado en el certamen
              nacional de jóvenes creadores en la Real Academia de Bellas Artes
              de San Fernando.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                gap: "16px",
              }}
            >
              <CardMedia
                component="img"
                image={data.biography.image}
                style={{ width: "200px" }}
              />
              <CardMedia
                component="img"
                image={data.biography.cvImage}
                style={{ width: "200px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                gap: "16px",
              }}
            >
              <Typography variant="body2" paragraph>
                D.U.M.B.O. Brooklyn, Nueva York.
              </Typography>
            </Box>
            <Typography variant="body2" paragraph>
              {data.biography.participations.join(", ")}.
            </Typography>
            <Typography variant="body2" paragraph>
              Actualmente, presento en YouTube mi canal cultural "LOS POLLOS
              HERMANOS DE CRISPÍN", que está en su 10ª temporada, con 106k
              visualizaciones, 7,6k de "Likes", 4k "Subscriptores", 229
              episodios y 73 entrevistados.
              <br />
              <a
                href={data.biography.currentWork.youtubeChannel.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.biography.currentWork.youtubeChannel.name}
              </a>
            </Typography>
          </CardContent>
        );
      case "courses":
        return (
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Cursos y Talleres
            </Typography>
            <List>
              {data.courses.map((course, index) => (
                <ListItem
                  key={index}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <ListItemText
                    primary={course.title}
                    secondary={
                      course.instructors ? course.instructors.join(", ") : ""
                    }
                  />
                  <ListItemText secondary={course.location} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        );
      case "awards":
        return (
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Premios
            </Typography>
            <List>
              {data.awards.map((award, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={award.title}
                    secondary={
                      award.location
                        ? award.location
                        : award.institution
                        ? award.institution
                        : award.organization
                    }
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        );
      case "works":
        return (
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Trabajos Realizados
            </Typography>
            <List>
              {data.works.map((work, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={work.title}
                    secondary={
                      work.location
                        ? work.location
                        : work.client
                        ? work.client
                        : ""
                    }
                  />
                  {work.books && (
                    <List>
                      {work.books.map((book, bookIndex) => (
                        <ListItem key={bookIndex}>
                          <ListItemText primary={book} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </ListItem>
              ))}
            </List>
          </CardContent>
        );
      case "collectionsAndMuseums":
        return (
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Colecciones y Museos
            </Typography>
            <List>
              {data.collectionsAndMuseums.map((collection, index) => (
                <ListItem key={index}>
                  <ListItemText primary={collection} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        );
      default:
        return null;
    }
  };

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
      >
        <Card
          sx={{
            minWidth: isMobile ? "100%" : isTablet ? "70%" : "900px",
            maxWidth: "900px",
            height: "70vh",
            backgroundColor: "#f5f5f5",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box sx={{ height: "100%", overflowY: "auto", padding: "20px" }}>
            {renderContent()}
          </Box>
          <IconButton
            onClick={handlePrevious}
            disabled={!data}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={!data}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowForward />
          </IconButton>
        </Card>
      </Box>
    </Container>
  );
};

export default WhoAmIContent;
