import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import FacebookIcon from "@mui/icons-material/Facebook";

const ContactContent = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Cargar datos desde el archivo JSON
    axios
      .get("/data/contact.json")
      .then((response) => {
        setData(response.data.sections);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  if (data.length === 0) return <div>Loading...</div>;

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ height: "70vh", overflow: "hidden", scrollbarWidth: "none" }}
      >
        {data.map((section, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{
              width: "100%",
              backgroundColor: "#f5f5f5",
              boxShadow: 3,
              marginBottom: 3,
            }}
          >
          <Box sx={{ padding: "20px" }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {section.title}
              </Typography>
              {section.subsections.map((subsection, subIndex) => (
                <Box key={subIndex} mt={2}>
                  <Typography variant="h6" component="div" gutterBottom>
                    {subsection.title}
                  </Typography>
                  <List>
                    {subsection.items.map((item, itemIndex) => (
                      <ListItem key={itemIndex}>
                        <ListItemIcon>
                          {item.type === "phone" && <PhoneIcon />}
                          {item.type === "email" && <EmailIcon />}
                          {item.type === "text" && <FacebookIcon />}
                          {item.type === "address" && <HomeIcon />}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="body1" component="span">
                              {item.type === "phone" ? (
                                <a href={`tel:${item.content}`}>
                                  {item.content}
                                </a>
                              ) : item.type === "email" ? (
                                <a href={`mailto:${item.content}`}>
                                  {item.content}
                                </a>
                              ) : (
                                item.content
                              )}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ))}
            </CardContent>
          </Box>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default ContactContent;
