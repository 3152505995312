import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  IconButton,
  Stack,
  CardMedia,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const PressContent = () => {
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isShortScreen = useMediaQuery("(max-height:800px)");

  useEffect(() => {
    // Cargar datos desde el archivo JSON
    axios
      .get("/data/press.json")
      .then((response) => {
        const loadedItems = response.data.sections.flatMap((section) =>
          section.subsections.flatMap((subsection) =>
            subsection.items.map((item) => ({
              ...item,
              mainTitle: section.title,
              subsectionTitle: subsection.title,
            }))
          )
        );
        setData(loadedItems);
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    const interval = setInterval(() => {
      handleNext();
    }, 120000); // 2 minutos

    return () => clearInterval(interval);
  }, [data]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  const handleIndexClick = (index) => {
    setCurrentIndex(index);
  };

  if (data.length === 0) return <div>Loading...</div>;

  const currentItem = data[currentIndex];

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
        sx={{ height: "80vh" }}
      >
        <Card
          variant="outlined"
          sx={{
            minWidth: isMobile ? "100%" : isTablet ? "70%" : "900px",
            maxWidth: isMobile ? "100vw" : "900px",
            backgroundColor: "#f5f5f5",
            boxShadow: 3,
            overflow: "hidden",
            position: "relative",
          }}
        >
          <Box
            sx={{
              height: "80vh",
              overflowY: "scroll",
              scrollbarWidth: "none",
              WebkitOverflowScrolling: "touch",
              padding: "20px",
            }}
          >
            <CardContent>
              {currentItem.mainTitle && (
                <Typography variant="h5" component="div" gutterBottom>
                  {currentItem.mainTitle}
                </Typography>
              )}
              {currentItem.subsectionTitle && (
                <Typography variant="h6" component="div" gutterBottom>
                  {currentItem.subsectionTitle}
                </Typography>
              )}
              <Typography variant="h6" component="div" gutterBottom>
                {currentItem.title}
                <CardMedia component="img" src={currentItem.image} style={{width: "50px"}} />
              </Typography>
              <Typography variant="body1" paragraph>
                {currentItem.author}
              </Typography>
              <Typography variant="body2" paragraph>
                {currentItem.description}
              </Typography>
            </CardContent>
          </Box>
          <IconButton
            onClick={handlePrevious}
            disabled={data.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              left: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={data.length === 0}
            sx={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateY(-50%)",
            }}
          >
            <ArrowForward />
          </IconButton>
        </Card>
        <Box
          sx={{
            justifyContent: "center",
            padding: "10px",
            margin: "10px",
          }}
        >
          <Typography variant="body2" sx={{ color: "grey.500" }}>
            {currentIndex + 1} / {data.length}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PressContent;
