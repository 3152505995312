import React, { useState, useEffect } from "react";
import { Container, Box, IconButton, Grid, CardMedia } from "@mui/material";
import { YouTube, Instagram, Facebook } from "@mui/icons-material";
import { ReactComponent as TikTokIcon } from "../assets/tiktok.svg";

const Footer = () => {
  const [isVertical, setIsVertical] = useState(false);

  const iconStyle = { width: 32, height: 32, color: "#000" };

  useEffect(() => {
    const handleResize = () => {
      const isVerticalOrientation =
        window.innerHeight > window.innerWidth &&
        (window.innerWidth <= 768 || window.innerHeight <= 1024); 
      setIsVertical(isVerticalOrientation);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        py: 1,
        textAlign: "center",
        maxHeight: "20vh",
        backgroundColor: "#D29B07",
        position: "relative",
      }}
    >
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Box>&copy; {new Date().getFullYear()} Crispin Mateos Mateos</Box>
          </Grid>
          <Grid item>
            <IconButton
              href="https://www.youtube.com/channel/UCFaKT2eaLumXb4lHz3tllMw"
              target="_blank"
              aria-label="YouTube"
            >
              <YouTube style={iconStyle} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              href="https://www.instagram.com/lospolloshermanospop/"
              target="_blank"
              aria-label="Instagram"
            >
              <Instagram style={iconStyle} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              href="https://www.tiktok.com/@lospolloshermanostiktok"
              target="_blank"
              aria-label="TikTok"
            >
              <TikTokIcon style={iconStyle} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              href="https://www.facebook.com/crispinpop"
              target="_blank"
              aria-label="Facebook"
            >
              <Facebook style={iconStyle} />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          position: isVertical ? "static" : "absolute",
          right: isVertical ? "auto" : 20,
          bottom: isVertical ? "auto" : 20,
          width: isVertical ? "33%" : "auto",
          mx: isVertical ? "auto" : 0,
          textAlign: isVertical ? "center" : "right",
        }}
      >
        <CardMedia
          component="img"
          alt="contador de visitas"
          src="https://counter2.optistats.ovh/private/contadorvisitasgratis.php?c=bq2ctajcxhcnzqw7e48rm34qwc1c7xcq"
        />
      </Box>
    </Box>
  );
};

export default Footer;
