import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  CardMedia,
  Button,
  Link,
  Grid,
  IconButton,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const logos = {
  amazon: "images/logo/amazonLogo.png",
  el_corte_ingles: "images/logo/elCorteInglesLogo.png",
  fnac: "images/logo/fnacLogo.png",
  casa_del_libro: "images/logo/casaDelLibroLogo.png",
};

const newImage = "../images/main/brooklynBridge.jpg";
const HomeContent = () => {
  const [entries, setEntries] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentEntry, setCurrentEntry] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isShortScreen = useMediaQuery("(max-height:800px)");

  useEffect(() => {
    // Cargar datos desde el archivo JSON
    axios
      .get("/data/home.json")
      .then((response) => {
        setEntries(response.data.entries);
        if (response.data.entries.length > 0) {
          setCurrentEntry(response.data.entries[0]);
        }
      })
      .catch((error) => console.error("Error loading data:", error));
  }, []);

  useEffect(() => {
    if (entries.length === 0) return;

    const interval = setInterval(() => {
      handleNext();
    }, 180000);

    return () => clearInterval(interval);
  }, [entries]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % entries.length;
      setCurrentEntry(entries[nextIndex]);
      return nextIndex;
    });
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      const prevIndexAdjusted =
        (prevIndex - 1 + entries.length) % entries.length;
      setCurrentEntry(entries[prevIndexAdjusted]);
      return prevIndexAdjusted;
    });
  };

  if (!currentEntry) return <div>Loading...</div>;

  return (
    <Container
      sx={{
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        margin: 0,
        padding: 0,
        ...(isShortScreen ? {} : { display: "flex" }),
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
      >
        <Card
          variant="outlined"
          sx={{
            backgroundColor: "#f5f5f5",
            boxShadow: 3,
          }}
        >
          <Box
            sx={{
              padding: isMobile ? "10px" : isTablet ? "15px" : "20px",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: isMobile ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  style={
                    isMobile
                      ? { width: "30%" }
                      : isTablet
                      ? { width: "47%" }
                      : { width: "100%" }
                  }
                  image={currentEntry.image}
                  alt={currentEntry.title}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <CardContent>
                  <Typography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    variant="h5"
                    component="div"
                    gutterBottom
                  >
                    {currentEntry.title}
                    <CardMedia
                      component="img"
                      style={
                        !isMobile
                          ? {
                              marginLeft: "20px",
                              width: "20%",
                            }
                          : {
                              width: "30%",
                              marginLeft: "10px",
                            }
                      }
                      src={currentEntry.newImage}
                    ></CardMedia>
                  </Typography>
                  <Typography variant="h6" color="textSecondary" gutterBottom>
                    {currentEntry.subtitle}
                  </Typography>
                  <Box
                    component="div"
                    sx={{
                      display: isMobile ? "block" : "block",
                      alignItems: isMobile ? "center" : "flex-start",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    {isMobile && (
                      <CardMedia
                        component="img"
                        style={{
                          float: "left",
                          width: "50%",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                        image={currentEntry.image}
                        alt={currentEntry.title}
                      />
                    )}
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ textAlign: "justify" }}
                    >
                      {currentEntry.description}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {currentEntry.availability}
                  </Typography>
                  <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    {currentEntry.links &&
                      Object.entries(currentEntry.links).map(([key, url]) => (
                        <Grid item key={key} xs={3} sm={3} md={3}>
                          <Button
                            variant="contained"
                            component={Link}
                            href={url}
                            target="_blank"
                            sx={{
                              padding: 0,
                              backgroundColor: "#f5f5f5",
                              "&:hover": { backgroundColor: "#f0f0f0" },
                            }}
                          >
                            <CardMedia
                              component="img"
                              image={logos[key]}
                              sx={{
                                width: 50,
                                height: 50,
                                objectFit: "contain",
                                padding: 1,
                              }}
                            />
                          </Button>
                        </Grid>
                      ))}
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                      >
                        Y en la editorial PALABRAS DE AGUA.
                      </Typography>
                      <Button
                        variant="contained"
                        component={Link}
                        href={"https://palabrasdeaguaeditorial.com/"}
                        target="_blank"
                        sx={{
                          padding: 0,
                          backgroundColor: "#f5f5f5",
                          "&:hover": { backgroundColor: "#f0f0f0" },
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={currentEntry.editorialImage}
                          sx={{
                            width: 50,
                            height: 50,
                            objectFit: "contain",
                            padding: 1,
                            display: "inline-block",
                          }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default HomeContent;
